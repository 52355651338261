import './styles/app.scss';

import * as $ from 'jquery';

// Production builds don't include Foundaiton without the node.js style require,
// for some reason
import 'foundation-sites';
require('foundation-sites');
import 'slick-carousel';
require('slick-carousel');

$(document).foundation();

$('[data-header-slider]').slick({
  accessibility: false,
  autoplay: true,
  autoplaySpeed: 5000,
  arrows: false,
  draggable: false,
  fade: true,
  pauseOnHover: true,
  speed: 150
});

// Foundation needs JQuery to work, so might as well have some nostalgic moments
// with JQuery
$(document).ready(function() {
  $('.back-to-top').click(function(event) {
    event.preventDefault();
    event.stopPropagation();

    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });

    // The entire page is inside of a container, so we sadly can't use the
    // browser's built in smooth scrolling here
    $('#main-container').animate({ scrollTop: 0 }, 250, 'swing');
  });
});
